import React from "react";
import img1 from '../assets/services/image.png'
import img2 from '../assets/services/image (1).png'
import img3 from '../assets/services/image (2).png'
import img4 from '../assets/services/image (3).png'
import img5 from '../assets/services/image (4).png'
import img6 from '../assets/services/image (5).png'

export const Services = (props) => {

  
 const Services= [
    {
      "icon": img1,
      "name": "Comprehensive Coverage",
      "text": "Provides all-around protection against damage to your vehicle from non-collision incidents, including theft, vandalism, fire, natural disasters, and more, ensuring complete peace of mind."
    },
    {
      "icon": img2,
      "name": "Collision Coverage",
      "text": "Covers the cost of repairs or replacement of your vehicle if it is damaged in an accident, regardless of who is at fault, helping you get back on the road quickly."
    },
    {
      "icon": img3,
      "name": "Liability Insurance",
      "text": "Protects you from financial loss if you are found responsible for an accident that causes injury to others or damages their property, covering legal fees, medical expenses, and repair costs."
    },
    {
      "icon": img4,
      "name": "Uninsured/Underinsured Motorist Coverage",
      "text": "Safeguards you against accidents involving drivers who lack sufficient insurance, covering medical expenses, lost wages, and vehicle repairs if the at-fault party cannot pay."
    },
    {
      "icon": img5,
      "name": "Personal Injury Protection (PIP)",
      "text": "Provides coverage for medical expenses, lost income, and other related costs for you and your passengers, regardless of who is at fault in an accident, ensuring financial support during recovery."
    },
    {
      "icon": img6,
      "name": "Roadside Assistance",
      "text": "Offers 24/7 support for breakdowns, including towing, battery jump-starts, flat tire changes, lockout services, and emergency fuel delivery, keeping you safe and mobile when the unexpected happens."
    }
  ]
  return (
    <>
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            Renew All Types Vehicle Insurance Simple And Affordable
          </p>
        </div>
        <div className="row">
          {Services
            ? Services.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        style={{
                          borderRadius: "50%",
                          background: "linear-gradient(to right, #ff6363 0%, #540e0e 100%)",
                          height: "100px",
                          width: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={d.icon}
                          style={{ height: "50px", width: "50px" }}
                        />
                      </div>
                    </div>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
        {/* <div>
        <div className="row service-row"  style={{padding:'50px'}}>
          <div className="col-lg-6 col-md-12 col-xs-12">
            <h2>Get Insured In 3 Easy Steps </h2>
          </div>
          <div className="col-lg-6 col-md-12 col-xs-12" >
            <ul>
              <li style={{padding:'15px',fontSize:'20px'}}><span style={{borderRadius:'50%',backgroundColor:'#b10000',padding:'10px 20px' ,color:'#fff'}}>1</span>  Fill details about yourself and your vehicle.</li>
              <li style={{padding:'15px',fontSize:'20px'}}><span style={{borderRadius:'50%',backgroundColor:'#b10000',padding:'10px 20px' ,color:'#fff'}}>2</span> choose the best insurance for your vehicle.</li>
              <li style={{padding:'15px',fontSize:'20px'}}><span style={{borderRadius:'50%',backgroundColor:'#b10000',padding:'10px 20px' ,color:'#fff'}}>3</span> activate your policy on the spot with no wait time.</li>
            </ul>
          </div>
        </div>
      </div> */}
      </>
  );
};
