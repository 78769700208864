import React from "react";
import timeimg from "../assets/image/save-time.png";
import coverage from "../assets/image/coverage.png";
import moneyimg from "../assets/image/save-money.png";

export const Features = (props) => {
  const Features = [
    {
      icon: timeimg,
      title: "Save Time",
      text: "Get insured quickly with our streamlined online application process, allowing you to compare quotes, customize your coverage, and secure your policy within minutes—no lengthy paperwork required",
    },
    {
      icon: moneyimg,
      title: "save money",
      text: "Enjoy competitive rates and exclusive discounts tailored to your driving history, vehicle type, and coverage needs, ensuring you receive the best value for your money.",
    },
    {
      icon: coverage,
      title: "immediate coverage",
      text: "Drive with confidence knowing that your insurance coverage starts as soon as your policy is issued. No waiting periods or delays—just immediate, reliable protection from the moment you need it.",
    },
  ];
  return (

      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Features</h2>
          </div>
          <div className="row">
            {Features
              ? Features.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-xs-12 col-md-4">
                    {" "}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        style={{
                          borderRadius: "50%",
                          background: "linear-gradient(to right, #ff6363 0%, #540e0e 100%)",
                          height: "100px",
                          width: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={d.icon}
                          style={{ height: "50px", width: "50px" }}
                        />
                      </div>
                    </div>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>

  );
};
